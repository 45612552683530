import React, { Component } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from '../components/section';
import styled from 'styled-components';
import ContactForm from '../components/form';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

class Contact extends Component {

  render() {
    return (
      <Layout {...this.props} transparentHeader>
        <SEO title="Contact" />
        <Section>
          <Col style={{marginTop: 128, alignItems: 'center'}}>
            <h1>
              Contact us
            </h1>
            <ContactForm style={{width: 600, marginTop: 24}}/>
          </Col>
        </Section>
      </Layout>
    );
  }

}

export default Contact;
